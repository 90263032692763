import { Button, TextField } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import styles from '../../../assets/scss/register.module.scss'
import RegisterContext from '../../../providers/register'

export default function Personal() {
    const {registerFormData, updateFormData, handleNext} = useContext(RegisterContext);
    const personal = registerFormData.personal;

    const [formDirtyFields, setFormDirtyFields] = useState({
        first_name: false,
        last_name: false,
        email: false,
        password: false,
        repeat_password: false
    });

    const helperText= {
        first_name: 'Please enter your first name.',
        last_name: 'Please enter your last name.',
        email: 'Please enter a valid email.',
        password: 'Password must be at least 8 characters.',
        repeat_password: 'Password doesn\'t match.'
    }

    

    function handleTextField(e) {
        const name = e.target.name;
        const value = e.target.value;
        const type = e.target.type;

        if(
            value === undefined || 
            value == '' || 
            (type == 'email' && !isValidEmail(value)) || 
            (type == 'password' && !isValidPassword(value)) ||
            (name == 'repeat_password' && !isPasswordMatch(value))
        ) {
            setFormDirtyFields({...formDirtyFields, [name]: true});
        } else {
            setFormDirtyFields({...formDirtyFields, [name]: false});
            personal[name] = value;
        }

        if(type == "password" && !isPasswordMatch(value)) {
            setFormDirtyFields({ ...formDirtyFields, repeat_password: true });
        }
    }

    function isValidEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    function isValidPassword(pass) {
        // if(pass.length < 8) {
        //     return false;
        // }

        return true;
    }

    function isPasswordMatch(pass) {
        if(pass === personal.password) {
            return true
        }

        return false
    }

    function submit(e) {
        e.preventDefault();
        
        let isFormValid = false;
        for (const key in formDirtyFields) {
            if (Object.hasOwnProperty.call(formDirtyFields, key)) {
                const fieldValidation = formDirtyFields[key];
                if(fieldValidation || personal[key] == undefined || personal[key] == '') {
                    isFormValid = false;
                    setFormDirtyFields({...formDirtyFields, [key]: true});
                    break;
                }
            }
            
            isFormValid = true;
        }
        
        if(isFormValid) {
            updateFormData('personal', personal);
            handleNext();
        }
        
        
        return false;
    }

    return (
        <React.Fragment>
            <form noValidate autoComplete="off" onSubmit={submit}>
                <div className={styles.formField}>
                    <TextField defaultValue = {personal.first_name}
                        label = "First name"
                        variant = "outlined"
                        name="first_name"
                        classes = {{root: styles.input}}
                        onChange={handleTextField}
                        error={formDirtyFields.first_name}
                        helperText={formDirtyFields.first_name? helperText.first_name : ''}
                        required 
                    />
                </div>
                <div className={styles.formField}>
                    <TextField defaultValue = {personal.last_name}
                        label = "Last name"
                        name="last_name"
                        variant = "outlined"
                        classes = {{root: styles.input}}
                        onChange={handleTextField}
                        error={formDirtyFields.last_name}
                        helperText={formDirtyFields.last_name? helperText.last_name : ''}
                        required 
                    />
                </div>
                <div className={styles.formField}>
                    <TextField defaultValue = {personal.email}
                    label = "Email"
                    type = "email"
                    name="email"
                    variant = "outlined"
                    classes = {{root: styles.input}}
                    onChange={handleTextField}
                    error={formDirtyFields.email}
                    helperText={formDirtyFields.email? helperText.email : ''}
                    required />
                </div>
                <div className={styles.formField}>
                    <TextField defaultValue = {personal.email}
                        label = "Password"
                        type = "password"
                        name="password"
                        variant = "outlined"
                        classes = {{root: styles.input}}
                        onChange={handleTextField}
                        error={formDirtyFields.password}
                        helperText={formDirtyFields.password? helperText.password : ''}
                        required
                    />
                </div>
                <div className={styles.formField}>
                    <TextField defaultValue = {personal.email}
                        label = "Repeat Password"
                        type = "password"
                        name="repeat_password"
                        variant = "outlined"
                        classes = {{root: styles.input}}
                        onChange={handleTextField}
                        error={formDirtyFields.repeat_password}
                        helperText={formDirtyFields.repeat_password? helperText.repeat_password : ''}
                        required
                    />
                </div>

                <div className={styles.formField}>
                    <Button variant="contained" color="primary" disableElevation type="submit" classes={{root: styles.btn}}>Next step</Button>
                </div>
            </form>
        </React.Fragment>
    )
}

import { Container, Grid } from '@material-ui/core'
import React from 'react'
import Layout from '../components/layout'
import GridRow from '../components/_grid-row'
import registerStyles from '../assets/scss/register.module.scss'
import RegisterStepper from '../components/register/reg-stepper'
import { RegisterProvider } from '../providers/register'



export default function Register() {

    return (
        <React.Fragment>
            <Layout pageName="Create Account">
                <Container maxWidth="xl">
                    <GridRow>
                        <Grid container spacing={3} justify="center">
                            <Grid item xs lg={8}>
                                <div className={[registerStyles.registerForm, "register"].join(" ")}>
                                   <RegisterProvider>
                                       <RegisterStepper />
                                   </RegisterProvider>
                                </div>
                            </Grid>
                        </Grid>
                    </GridRow>
                </Container>
            </Layout>
        </React.Fragment>
    )
}

import React, { useContext } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';

import stepperStyles from '../../assets/scss/register.module.scss';
import Personal from './steps/peronsal';
import RegisterContext from '../../providers/register';
import Health from './steps/health';
import More from './steps/more';
import { StepConnector, withStyles } from '@material-ui/core';
import UserContext from '../../providers/user-provider';
import { navigate } from 'gatsby-link';

function getSteps() {
    return ['Personal', 'Health', 'more', 'Payment'];
}

function getStepContent(step) {
    switch (step) {
        case 0:
            return <Personal />;
        case 1:
            return <Health />;
        case 2:
            return <More />;
        case 3:
            return 'This is payment info';
        default:
            return 'Unknown step';
    }
}

function getHeader(step) {
    switch (step) {
        case 0:
            return 'Create a profile and register';
            break;
        case 1:
            return 'Tell us about yourself';
            break;
        case 2:
            return 'Getting closer...';
            break;
        case 3:
            return 'Almost there, chimp!';
            break;
        default:
            return 'undefined step';
            break;
    }
}

export default function RegisterStepper() {
    const steps = getSteps();

    const { activeStep, handleNext, handleBack } = useContext(RegisterContext);
    const { user } = useContext(UserContext);
    const ConnectorWithStyles = withStyles({
        alternativeLabel: {
            top: 10,
            left: 'calc(-50% + 16px)',
            right: 'calc(50% + 16px)',
        },
        active: {
            '& $line': {
                borderColor: '#ff9800',
            },
        },
        completed: {
            '& $line': {
                borderColor: '#ff9800',
            },
        },
        line: {
            borderColor: '#eaeaf0',
            borderTopWidth: 3,
            borderRadius: 1,
        },
    })(StepConnector);

    return (
        <React.Fragment>
            {user ? navigate('/profile')
                :
                <>

                    <div className={stepperStyles.registerHeader}>
                        <h1 className="h1 orange">
                            {getHeader(activeStep)}
                        </h1>
                        <p className="text-01">
                            Welcome to Beach Monkeyz, let’s get stronger than yesterday. We have an event coming up and we’d love to have you on our team.
                </p>
                    </div>
                    <div className={stepperStyles.stepper}>
                        <Stepper activeStep={activeStep} classes={{ root: `${stepperStyles.stepperWrapper} register-stepper-wrapper` }} connector={<ConnectorWithStyles />}>
                            {steps.map(label => (
                                <Step key={label} classes={{ completed: stepperStyles.completed }}>
                                    <StepLabel></StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <div className="steps">
                            {getStepContent(activeStep)}
                        </div>
                    </div>

                </>}
        </React.Fragment>
    )
}

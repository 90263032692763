import { Button, FormHelperText } from '@material-ui/core';
import React, { useContext, useState } from 'react'
import styles from '../../../assets/scss/register.module.scss'
import RegisterContext from '../../../providers/register'


export default function More() {
    const {registerFormData, updateFormData, handleNext, handleBack, postRegistration} = useContext(RegisterContext);
    const more = registerFormData.more;

    const [formDirtyFields, setFormDirtyFields] = useState({
        goals: false,
        describe: false,
    });

    const helperText= {
        goals: 'Please select at least one goal.',
        describe: 'Please select what describes you the most.',
    }

    function handleCheckbox(e) {
        const name = e.target.name;
        const value = e.target.value;
        const isChecked = e.target.checked;

        if(isChecked) {
            more[name].push(value);
            setFormDirtyFields({...formDirtyFields, [name]: false});
        } else {
            const index = more[name].findIndex(item => item == value);
            more[name].splice(index, 1);
        }

        if(more[name].length < 1) {
            setFormDirtyFields({...formDirtyFields, [name]: true});
        } 
        
    }

    function handleRadio(e) {
        const value = e.target.value;
        
        setFormDirtyFields({...formDirtyFields, 'describe': false});
        more['describe'] = value;
    }

    function submit(e) {
        e.preventDefault();

        let isFormValid = false;
        for (const key in formDirtyFields) {
            if (Object.hasOwnProperty.call(formDirtyFields, key)) {
                const fieldValidation = formDirtyFields[key];
                if(fieldValidation || more[key] === undefined || more[key] === '' || more[key].length < 1) {
                    isFormValid = false;
                    setFormDirtyFields({...formDirtyFields, [key]: true});
                    break;
                }
            }
            
            isFormValid = true;
        }
        
        if(isFormValid) {
            updateFormData('more', more);
            // handleNext();
            postRegistration();
        }

        return false;
    }

    return (
        <React.Fragment>
            <form noValidate autoComplete="off" onSubmit={submit}>
                <div className={styles.formField}>
                    <label className={styles.moreLabel}>
                        What are your goals?
                    </label>
                    <FormHelperText error={true}>{formDirtyFields.goals? helperText.goals : ''}</FormHelperText>
                    <label className={styles.radioBtn}>
                        <input name="goals" value="Lose some weight" type="checkbox" className={formDirtyFields.goals? styles.invalid : ''} onChange={handleCheckbox}/>
                        <span className={`${styles.checkBox} check-box`}>Lose some weight</span>
                    </label>
                    <label className={styles.radioBtn}>
                        <input name="goals" value="Get toned and maintain my shape" type="checkbox" className={formDirtyFields.goals? styles.invalid : ''} onChange={handleCheckbox}/>
                        <span className={`${styles.checkBox} check-box`}>Get toned and maintain my shape</span>
                    </label>
                    <label className={styles.radioBtn}>
                        <input name="goals" value="Be more flexible" type="checkbox" className={formDirtyFields.goals? styles.invalid : ''} onChange={handleCheckbox}/>
                        <span className={`${styles.checkBox} check-box`}>Be more flexible</span>
                    </label>
                    <label className={styles.radioBtn}>
                        <input name="goals" value="Become stronger" type="checkbox" className={formDirtyFields.goals? styles.invalid : ''} onChange={handleCheckbox}/>
                        <span className={`${styles.checkBox} check-box`}>Become stronger</span>
                    </label>
                    <label className={styles.radioBtn}>
                        <input name="goals" value="Increase my endurance" type="checkbox" className={formDirtyFields.goals? styles.invalid : ''} onChange={handleCheckbox}/>
                        <span className={`${styles.checkBox} check-box`}>Increase my endurance</span>
                    </label>
                </div>

                <div className={styles.formField}>
                    <label className={styles.moreLabel}>
                        How would you describe yourself?
                    </label>
                    <FormHelperText error={true}>{formDirtyFields.describe? helperText.describe : ''}</FormHelperText>
                    <label className={styles.radioBtn}>
                        <input name="describe" value="Novice (beginner)" type="radio" className={formDirtyFields.describe? styles.invalid : ''} onChange={handleRadio}/>
                        <span className={`${styles.checkBox} check-box`}>Novice (beginner)</span>
                    </label>
                    <label className={styles.radioBtn}>
                        <input name="describe" value="Weekend Warrior (only workout on weekends)" className={formDirtyFields.describe? styles.invalid : ''} type="radio" onChange={handleRadio}/>
                        <span className={`${styles.checkBox} check-box`}>Weekend Warrior (only workout on weekends)</span>
                    </label>
                    <label className={styles.radioBtn}>
                        <input name="describe" value="Fitness enthusiast" type="radio" className={formDirtyFields.describe? styles.invalid : ''} onChange={handleRadio}/>
                        <span className={`${styles.checkBox} check-box`}>Fitness enthusiast</span>
                    </label>
                    <label className={styles.radioBtn}>
                        <input name="describe" value="Recreational athlete" type="radio" className={formDirtyFields.describe? styles.invalid : ''} onChange={handleRadio}/>
                        <span className={`${styles.checkBox} check-box`}>Recreational athlete</span>
                    </label>
                    <label className={styles.radioBtn}>
                        <input name="describe" value="Competitive athlete" type="radio" className={formDirtyFields.describe? styles.invalid : ''} onChange={handleRadio}/>
                        <span className={`${styles.checkBox} check-box`}>Competitive athlete</span>
                    </label>
                </div>
                <div className={styles.formField} style={{marginTop: '40px'}}>
                    <Button disableElevation onClick={handleBack} classes={{root: `${styles.btn} ${styles.backBtn}`}}  style={{marginRight: '16px'}}>Back</Button>
                    <Button variant="contained" color="primary" disableElevation type="submit" classes={{root: styles.btn}}>Next step</Button>
                </div>
            </form>
        </React.Fragment>
    )
}

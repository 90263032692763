import { Button, FormControlLabel, FormHelperText, Grid, MenuItem, TextField } from '@material-ui/core';
import React, { useContext, useState } from 'react'
import styles from '../../../assets/scss/register.module.scss'
import RegisterContext from '../../../providers/register'

export default function Health() {
    const {registerFormData, updateFormData, handleNext, handleBack} = useContext(RegisterContext);
    const health = registerFormData.health;

    const [formDirtyFields, setFormDirtyFields] = useState({
        weight: false,
        age: false,
        gender: false
    });

    const helperText= {
        weight: 'Please select your weight.',
        age: 'Please enter your age.',
        gender: 'Please select your gender.'
    }

    function handleTextField(e) {
        const name = e.target.name;
        const value = e.target.value;
        const type = e.target.type;

        if(
            value === undefined || 
            value === '' ||
            (name === 'age' && !isValidNumber(value))
        ) {
            setFormDirtyFields({...formDirtyFields, [name]: true});
        } else {
            setFormDirtyFields({...formDirtyFields, [name]: false});
            health[name] = value;
        }
    }

    function isValidNumber (number) {
        
        return !isNaN(number);
    }

    function handleRadio(e) {
        const value = e.target.value;
        
        setFormDirtyFields({...formDirtyFields, 'gender': false});
        health['gender'] = value;
    }

    function submit(e) {
        e.preventDefault();
        
        let isFormValid = false;
        for (const key in formDirtyFields) {
            if (Object.hasOwnProperty.call(formDirtyFields, key)) {
                const fieldValidation = formDirtyFields[key];
                if(fieldValidation || health[key] === undefined || health[key] === '') {
                    isFormValid = false;
                    setFormDirtyFields({...formDirtyFields, [key]: true});
                    break;
                }
            }
            
            isFormValid = true;
        }
        
        if(isFormValid) {
            updateFormData('health', health);
            handleNext();
        }
        
        
        return false;
    }


    return (
        <React.Fragment>
            <form noValidate autoComplete="off" onSubmit={submit}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <div className={styles.formField}>
                            <TextField
                                select
                                defaultValue = {health.weight}
                                label = "Weight"
                                variant = "outlined"
                                name="weight"
                                classes = {{root: styles.input}}
                                onChange={handleTextField}
                                error={formDirtyFields.weight}
                                helperText={formDirtyFields.weight? helperText.weight : ''}
                                required 
                            >
                                <MenuItem value={'less than 45'}>
                                    Less than 45 Kg
                                </MenuItem>
                                <MenuItem value={'45 ~ 55'}>
                                    45 ~ 55 Kg
                                </MenuItem>
                                <MenuItem value={'55 ~ 65'}>
                                    55 ~ 65 Kg
                                </MenuItem>
                                <MenuItem value={'65 ~ 75'}>
                                    65 ~ 75 Kg
                                </MenuItem>
                                <MenuItem value={'75 ~ 85'}>
                                    75 ~ 85 Kg
                                </MenuItem>
                                <MenuItem value={'85 ~ 95'}>
                                    85 ~ 95 Kg
                                </MenuItem>
                                <MenuItem value={'95 ~ 105'}>
                                    95 ~ 105 Kg
                                </MenuItem>
                                <MenuItem value={'greater than 105'}>
                                    Greater than 105 Kg
                                </MenuItem>
                            </TextField>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={styles.formField}>
                            <TextField
                                defaultValue = {health.age}
                                label = "age"
                                variant = "outlined"
                                name="age"
                                classes = {{root: styles.input}}
                                onChange={handleTextField}
                                error={formDirtyFields.age}
                                helperText={formDirtyFields.age? helperText.age : ''}
                                required 
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={styles.formField}>
                            <div>
                                <label className={styles.genterLabel}>Gender</label>
                                <FormHelperText error={true}>{formDirtyFields.gender? helperText.gender : ''}</FormHelperText>
                            </div>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <label className={styles.radioBtn}>
                                        <input name="gender" value="M" type="radio" className={formDirtyFields.gender? styles.invalid : ''} onChange={handleRadio}/>
                                        <span className={`${styles.checkBox} check-box`}>Male</span>
                                    </label>
                                </Grid>
                                <Grid item xs={6}>
                                    <label className={styles.radioBtn}>
                                        <input name="gender" value="F" type="radio" className={formDirtyFields.gender? styles.invalid : ''} onChange={handleRadio}/>
                                        <span className={`${styles.checkBox} check-box`}>Female</span>
                                    </label>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
                <div className={styles.formField} style={{marginTop: '40px'}}>
                    <Button disableElevation onClick={handleBack} classes={{root: `${styles.btn} ${styles.backBtn}`}}  style={{marginRight: '16px'}}>Back</Button>
                    <Button variant="contained" color="primary" disableElevation type="submit" classes={{root: styles.btn}}>Next step</Button>
                </div>
            </form>
        </React.Fragment>
    )
}
